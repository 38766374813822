import { FC } from 'react';
import IconProps from './IconProps';

const RiskIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg onClick={onClick} className={`inline ${cursor} ${className}`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.123 5.95461C19.9241 5.9647 19.7239 5.9698 19.5225 5.9698C16.5353 5.9698 13.8106 4.84681 11.7471 3C9.68374 4.84675 6.95897 5.96968 3.97191 5.96968C3.7705 5.96968 3.5703 5.96458 3.3714 5.95449C3.12897 6.89103 3 7.87324 3 8.88557C3 14.32 6.7169 18.8863 11.7472 20.1811C16.7775 18.8863 20.4944 14.32 20.4944 8.88557C20.4944 7.87329 20.3654 6.89111 20.123 5.95461Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M11.8818 7.97363V12" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.8818 15.0788V15.4341" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.123 5.95461C19.9241 5.9647 19.7239 5.9698 19.5225 5.9698C16.5353 5.9698 13.8106 4.84681 11.7471 3C9.68374 4.84675 6.95897 5.96968 3.97191 5.96968C3.7705 5.96968 3.5703 5.96458 3.3714 5.95449C3.12897 6.89103 3 7.87324 3 8.88557C3 14.32 6.7169 18.8863 11.7472 20.1811C16.7775 18.8863 20.4944 14.32 20.4944 8.88557C20.4944 7.87329 20.3654 6.89111 20.123 5.95461Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M11.8818 7.97363V12" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.8818 15.0788V15.4341" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default RiskIcon;
